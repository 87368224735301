import React from "react";

function Booking(){
    return(
        <div className="booking">
            <iframe 
            width="100%" 
            height="100%"
            src="https://outlook.office365.com/owa/calendar/ArcadeFitness@ernst.work/bookings/"
            frameBorder="0" 
                marginWidth="0"
                marginHeight="0" 
                style={{border: "none", 
                        max_width:"100%", 
                        max_height:"100vh"}} 
                allowFullScreen
                webkitallowfullscreen="true"
                mozallowfullscreen="true" 
                msallowfullscreen="true">
            </iframe>
        </div>
    )
}

export default Booking;