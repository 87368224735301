import './App.css';

import Home from "./views/homeView";
import Prices from "./views/pricesView";
import Coaching from './views/coachingView';
import Gallery from "./views/galleryView";
import SignUp from "./views/signupView";
import Booking from "./views/bookingView";

import Header from './components/header';
import Footer from './components/footer';

import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header/>
        <Routes>
          <Route path="/" element={<Home />}/>
          <Route path="/Prices" element={<Prices />} />
          <Route path="/Coaching" element={<Coaching />} />
          <Route path="/Gallery" element={<Gallery />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/Booking" element={<Booking />} />
        </Routes>
        <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;
