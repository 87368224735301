import React from "react";

function importAll(r) {
    let images = [];
    let length = 0;
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); length++});
    return {images, length};
}

const {images, length} = importAll(require.context('../images/galleryImages/', false, /\.(png|jpe?g|svg)$/));

function ImageRender(){
    var renderImages = [];

    for(let i = 1; i < 12; i++){
        renderImages.push(<img className="gallery--img" src={images[`gallery-${i}.png`]}/>);
    }

    return renderImages;
}

function Gallery (){
    return(
        <div className="page--contact gallery">
            <h1 className="title--page">Galerij</h1>
            <div className="list--gallery">
                <ImageRender/>
            </div>
        </div>
    );
}

export default Gallery;