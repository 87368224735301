import React from "react";

import { Link } from 'react-router-dom';

import Img1 from "../images/Coaching-1.jpg";
import Img2 from "../images/Coaching-2.png";
import Img3 from "../images/Coaching-3.jpg";

class Card extends React.Component{

    handeClick(event){
        event.currentTarget.classList.toggle('hidden');
    }

    render(){
        return(
            <div className="hidden" onClick={this.handeClick}>{this.props.insert}</div>
        );
    }
}

function Coaching(){
    return(
        <div className="page--contact gallery">
            <h1 className="title--page">Begeleiding</h1>
            <div className="container--card">
                <Card insert={
                    <div className="card--coaching">
                        <h2 className="text--link tm-50">Filip personal trainer</h2>
                        <div className="container--images">
                            <img className="img--filip" src={Img1}/>
                            <img className="img--filip" src={Img3}/>
                        </div>
                        <div className="container--text">
                            <p className="text--coaching">Ik ben begonnen met trainen toen ik 16 jaar was. Ik was heel onzeker en mij inschrijven in een fitness was een grote stap. Ik had geen idee van wat ik moest doen of hoe ik eraan zou moeten beginnen. Ik ben mijn eigen opzoek werk beginnen doen en kreeg een hoop informatie die heel vaak tegenstrijdig was. Na 2 jaar diëten en trainingsmethodes testen, kon ik eindelijk zeggen wat werkte en wat niet. En nog het belangrijkste van allemaal, wat kan ik volhouden? Met de kennis die ik heb opgebouwd volg ik al 5 jaar een ‘dieet’. Ik zet dieet tussen haakjes, omdat het voor mij niet aanvoelt als een dieet, maar als leefstijl. Ik word wakker en denk na over wat ik ga eten vandaag. Waar heb ik zin in en wat kan ik nog eten/niet eten om dit vandaag in mijn voeding schema te steken. Ik eet dus elke dag anders. De afgelopen 5 jaar ben ik niet altijd 100% geweest met mijn ‘dieet’. Ik was niet altijd even gemotiveerd en soms lag de focus op andere dingen. Die 100% was dan op dat moment 70%, wat nog altijd genoeg was om vooruitgang te maken. Het kostte 5 minuten van mijn dag om een goed voedingsschema te maken, waar ik dus nog altijd vooruitgang mee ging boeken. Nu vandaag, 5 jaar later en slechts 5 minuten werk elke dag, kan ik naar voeding kijken en weet ik 90% van de tijd dit ‘is goed’ of dit ‘is niet goed’. Je moet je eens voorstellen hoeveel waarde dit heeft! Mijn doel is dan ook om mensen hierin wegwijs te maken en ervoor te zorgen dat ze een idee hebben van wat ze eten. Om dan te gaan beseffen dat het allemaal goed meevalt, als je op de juiste dingen focust. Veel mensen zijn in het begin altijd heel gemotiveerd om er volledig voor te gaan. Ze boeken vooruitgang, maar houden het niet lang vol. Terwijl, als je op de juiste dingen focust, je evenveel of meer vooruitgang boekt, maar met veel minder moeite.</p>
                            <p className="text--coaching">Nog even kort over mezelf. Ik zou niet weten waar ik gestaan zou hebben zonder fitness. Het heeft mij met zoveel meer geholpen dan er alleen maar goed uitzien. Meer zelfvertrouwen, een uitdaging durven aangaan, de mening van anderen aan de kant zetten, een goed gevoel hebben dat ik aan mezelf aan het werken ben, mensen die mij sneller aanspreken en mensen die meer respect voor je tonen.</p>
                        </div>
                        <p className="text--link">Klik hier voor zijn verhaal</p>
                    </div>
                }/>

                <Card insert={
                    <div className="card--coaching">
                        <h2 className="text--link tm-50">Bau's verhaal</h2>
                        <img className="img--bau" src={Img2}/>
                        <div className="container--text">
                            <p className="text--coaching">Afvallen is voor iedereen anders of beter gezegd ervaart iedereen anders.
                                                        Door de jaren heen heb ik meer maals na een bulk periode mezelf uitgedaagd weer af te vallen met het doel zo veel mogelijk spiermassa te behouden en zo veel mogelijk vet te verliezen.
                                                        De eerste keren heb ik dit voornamelijk gedaan op het gevoel, met lichte kennis over mijn voeding inname en de wekelijkse trainingen was dit volgens mij voldoende om mijn doelen te bereiken.
                                                        Af vallen lukte zeker, dat was nooit het probleem. Het probleem dat zich hier jaarlijks wel voordeed was de honger die terugkeert, te veel spiermassa verlies en net niet zo strak staan als ik had verwacht.</p>
                            <p className="text--coaching">Dit jaar had ik mijn doelen iets hoger gelegd dan voorheen en had ik mezelf een exact te behalen doel gegeven dus geen ruimte om op het gevoel te cutten(afvallen).
                                                        Verrassend genoeg was dit de meest succesvolle en makkelijkste cut voor mij tot nu toe. de Reden hiervoor simpelweg zijnde dat als je je voeding en training goed trackt en bijhoud en op elkaar afstemd je veel makkelijker je doelen haalt dan je zou denken.
                                                        De problemen met op gevoel trainen liggen niet in het gebrek aan motivatie of karakter en wil zeker ook niet zeggen dat je iets fout doet, maar in de kleine berekeningsfout die je maakt in de energie die je verbrand in vergelijking met de energie (voeding) die je opneemt.
                                                        in theorie en in de praktijk is het afval process niet meer dan een rekensom. je verbrand x aantal energie op een dag en vult die weer aan door te eten, eet iets minder dan je verbrand elke dag en je valt af, dit is de basis.</p>
                            <p className="text--coaching">De meest voorkomende fouten door op het gevoel te cutten zijn dat er vaak te veel of soms te weinig verschil zit tussen de verbrande energie en de opgenomen energie, wat in het eerste geval leid tot zeer slap en loom voelen en verlies van spiermassa en in het 2e geval wordt er amper tot geen progress gemaakt en bijgevolg geeft iemand op.
                                                        Door dit close op te volgen, zal je verbaasd zijn hoe ver je geraakt, hoeveel minder moeilijk het hele proces zal aanvoelen en hoeveel marge er is om af en toe iets lekkers te snacken.</p>
                        </div>
                        <p className="text--link">Klik hier voor zijn verhaal</p>
                    </div>
                }/>

            </div>
            <div className="tm-50">
                <div className="dp-flex">
                    <p className="text--link">Wil je meer weten over de prijzen</p>
                    <Link to="/Prices" className="call-to-action">Klik hier</Link>
                </div>
                <div className="dp-flex">
                    <p className="text--link">Wil je een begeleidingsgesprek met een personal coach inboeken?</p>
                    <Link to="/Booking" className="call-to-action">Klik hier</Link>
                </div>
            </div>
        </div>
    );
}

export default Coaching;