import React from "react";

function Prices (){
    return(
        <div className="page--contact prices">
            <h1 className="title--page">Promoties</h1>
            <div className="list--prices">
                <table style={{width: "100%"}}>
                    <tr className="list--content--tr">
                        <td className="table--left"><h3>Bij eerste bezoek</h3></td>
                        <td className="table--right"><h3>Plan je gratis training in (je kan dit ook via de website)</h3></td>
                    </tr>
                    <tr className="list--content--tr">
                        <td className="table--left"><h3>Wij nemen lopende abonnementen over van andere gyms</h3></td>
                        <td className="table--right"><h3>bezoek ons tijdens de tooguren voor meer uitleg over onze voorwaarden</h3></td>
                    </tr>
                </table>
            </div>
            <h1 className="title--page">Prijslijst</h1>
            <div className="list--prices">
                <div className="table--header">
                    <h2>Abonnementen</h2>
                    <h2>Prijs</h2>
                </div>
                <div className="list--content">
                    <div className="table--left">
                        <h3>Arcade 7/7 Abonnement 1 maand</h3>
                        <h3>Arcade 7/7 Abonnement 12 maanden</h3>
                        <h3>Arcade 7/7 Abonnement 12 maanden</h3>

                        <h3>10 beurtenkaart (Arcade 7/7)</h3>

                        <h3>Arcade Pro Abonnement 12 maanden</h3>
                        <h3>Arcade Pro Abonnement 12 maanden</h3>

                    </div>
                    <div className="table--right">
                        <h3>Per maand: 45,00€ - Totaal: 45,00€</h3>
                        <h3>Per maand: 32,50€ - Totaal: 390,00€</h3>
                        <h3>Domiciliëring - 36,00€</h3>

                        <h3>Per maand: ±45,00€ - Totaal: 90,00€</h3>

                        <h3>Per maand: 43,33€ - Totaal: 520,00€</h3>
                        <h3>Domiciliëring - 47,00€</h3>
                    </div>
                </div>
                <div className="table--header">
                    <h2>Eenmalige kost</h2>
                    <h2>Prijs</h2>
                </div>
                <div className="list--content">
                    <div className="table--left">
                        <h3>Instapkost</h3>
                    </div>
                    <div className="table--right">
                        <h3>10,00€</h3>
                    </div>
                </div>
                <div className="table--header">
                    <h2>Begeleiding</h2>
                    <h2>Prijs</h2>
                </div>
                <div className="list--content">
                    <div className="table--left">
                        <h3>Personal training</h3>
                        <h3>Trainingsschema + 1 uur personal training</h3>
                        <h3>Voedingsadvies</h3>
                    </div>
                    <div className="table--right">
                        <h3>40,00 €​</h3>
                        <h3>50,00€</h3>
                        <h3>50,00€</h3>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Prices;