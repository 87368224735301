import React from "react";

function signUp(){
    return(
        <div className="signin">
            <iframe 
                width="100%" 
                height="100%" 
                src="https://forms.microsoft.com/Pages/ResponsePage.aspx?id=dmhNdcG5RUWCzB0kKK4Lrhk4R7b3lJZOrUL1iH2fSDhUQk5SNzg4UUZQSk41SUNFS1RFT0g0SFA2WiQlQCN0PWcu&embed=true" 
                frameBorder="0" 
                marginWidth="0"
                marginHeight="0" 
                style={{border: "none", 
                        max_width:"100%", 
                        max_height:"100vh"}} 
                allowFullScreen
                webkitallowfullscreen="true"
                mozallowfullscreen="true" 
                msallowfullscreen="true"></iframe>
        </div>
    )
}

export default signUp;