import React from "react";

import insta from "../images/insta.svg";
import facebook from "../images/facebook.svg";

function Footer(){
    return(
        <div className="footer">
            <div className="footer--container">
                <p className="footer--label">instagram</p>
                <p className="footer--label">@arcadefitness</p>
                <a href="https://www.instagram.com/arcadefitness/" className="footer--link" target="_blank"><img src={insta}></img></a>
            </div>
            <div className="footer--container">
                <p className="footer--label">facebook</p>
                <p className="footer--label">Arcade Fitness</p>
                <a href="https://www.facebook.com/ArcadeFitnessSchoten" className="footer--link" target="_blank"><img src={facebook}></img></a>
            </div>
            <div className="center">
                <p>telefoon nummer <br/>03 735 16 48</p>
                <p>email<br/> info@arcadefitness.be</p>
            </div>
        </div>
    );
}

export default Footer;