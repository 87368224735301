import React from "react";
import { Link } from 'react-router-dom';

import fullLogo from "../images/_logo.svg";
import map from "../images/map.png";
import TeamImg from "../images/team/meetTheTeam.png"

import christmaspromo from "../images/christmas/christmaspromo.png"

import insta from "../images/insta.svg";
import facebook from "../images/facebook.svg";


function Home (){
    return(
        <div>
            <div className="hero--content">
                <img src={fullLogo}/>
                <p>The old school gym experience.</p>
                <div className="container--call-to-action">
                    {/* <div>
                        <img className="img--christmas" src={christmaspromo}/>
                    </div>
                    <h2 className="banner">Profiteer nu van onze kerstactie!<br/>
                    + Plan vanaf nu online je begeleidingsafspraak in!</h2> */}
                    <Link className="call-to-action" to="Prices">Bekijk de prijzen en promoties</Link>
                <div className="links--social-media">
                    <a href="https://www.instagram.com/arcadefitness/" target="_blank"><img src={insta}></img></a>
                    <a href="https://www.facebook.com/ArcadeFitnessSchoten" target="_blank"><img src={facebook}></img></a>
                </div>
                </div>
            </div>
            <div className="page--contact">
                <div className="content--left">
                <h1>Openingsuren</h1>
                <h2>Fitness</h2>
                <div className="hours">
                    <div className="container--text1">
                        <p>Alle dagen</p>
                    </div>
                    <div className="container--text2">
                        <p>06:00 - 23:59u</p>
                    </div>
                </div>
                <h2>Toog</h2>
                <div className="hours">
                    <div className="container--text1">
                        <p>maandag tot vrijdag</p>
                        <p>zaterdag, zondag en feestagen</p>
                    </div>
                    <div className="container--text2">
                        <p>9:30 - 12:30u  17:00 - 20:00u</p>
                        <p>gesloten</p>
                    </div>
                </div>
                </div>
                <div className="content--right">
                    <h1>Locatie</h1>
                    <a target="_blank" href="https://goo.gl/maps/ErGHN2ZGvCHAfzkE9"><img src={map}></img></a>
                    <p>Vordensteinstraat 133, 2900 Schoten</p>
                    <p className="text--info">Parking: Achteraan via de oprit. Voor foto's van de parking kijk op Galerij.</p>
                </div>
            </div>
            <div className="page--contact gallery">
                <h1 className="title--page">Meet the team</h1>
                <div className="img--meet-team">
                    <img src={TeamImg} />
                </div>
                <div className="content--team">
                <div className="person">
                        <h4>Filip Andriessen</h4>
                        <p className="text--dark">Voedingsadvies</p>
                        <p className="text--dark">Personal Training</p>
                    </div>  
                    <div className="person">
                        <h4>Thom Ernst</h4>
                    </div>
                </div>
            </div>
            <div className="page--contact gallery">
                <h1 className="title--page">Algemene info</h1>
                <div className="container--info">
                    <h3>We werken met een badgesysteem, hierdoor kan je in de fitness elke dag van de week van 6u tot 24u!</h3>
                    <h3>Eenmalige instapkost is €10</h3>
                    <h3>Handdoek lenen kost €1,5</h3>
                    <h3>Bij verlies van badge zal €10 aangerekend worden</h3>
                    <h3>Geen krachtbevorderende producten zoals anabole steroïde toegestaan</h3>
                    <h3>Dranken van thuis zijn toegestaan, we hebben ook een automaat</h3>
                    <h3>Respecteer de buren: Matig uw snelheid, laat geen zwerfvuil achter op de oprit of de parking</h3>
                </div>
            </div>
        </div>
    );
}

export default Home;