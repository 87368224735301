import React, {useState} from "react";

import logo from "../images/logo.svg";
import hamburger from "../images/hamburger.svg";

import { Link } from 'react-router-dom';

function Header(){

    const [isActive, setIsActive] = useState(true);

    const handleClick = () => {
        setIsActive(current => !current);
    };

    return(
        <header>
            <Link className="logo" to="/"><img src={logo}/></Link>
            <ul className="nav">
                <li><Link to="/">Home</Link></li>
                <li><Link to="/Prices">Prijzen</Link></li>
                <li><Link to="/coaching">Begeleiding</Link></li>
                <li><Link to="/Gallery">Galerij</Link></li>
                <li><Link to="/signup">Schrijf in</Link></li>
                <li><Link to="/Booking">Gesprek inboeken</Link></li>
            </ul>

            <div className="flyout">
                <a onClick={handleClick} className="hamburger"><img src={hamburger}/></a>
                <ul className="flyout--nav" style={{ display: isActive ? 'none' : ''}}>
                    <li><Link onClick={handleClick} to="/">Home</Link></li>
                    <li><Link onClick={handleClick} to="/Prices">Prijzen</Link></li>
                    <li><Link onClick={handleClick} to="/coaching">Begeleiding</Link></li>
                    <li><Link onClick={handleClick} to="/Gallery">Galerij</Link></li>
                    <li><Link onClick={handleClick} to="/signup">Inschrijven</Link></li>
                    <li><Link onClick={handleClick} to="/Booking">Gesprek inboeken</Link></li>
                </ul>
            </div>
        </header>
    );
}

export default Header;